import { List, OrderedMap, Record } from 'immutable';
import { camelCaseResponse, formatCurrency } from '../util';
import SplitQuantity from './split-quantity';

class Split extends Record({
  id: null,
  userId: null,
  name: null,
  items: null,
  claimed: false,
  displayColors: null,
  subtotal: null,
  tax: null,
  tip: null,
  serviceFee: null,
  totalPrice: null,
}) {
  static constructFromAPIResponse(apiResponse) {
    const displayColors = {
      backgroundColor: `#${apiResponse.display_colors.background_color}`,
      color: `#${apiResponse.display_colors.font_color}`,
    };
    const items = new List(apiResponse.items.map((i) => SplitItem.constructFromAPIResponse(i)));
    const mappedProps = camelCaseResponse(apiResponse);
    return new Split(Object.assign(mappedProps, { displayColors, items }));
  }
}

class SplitItem extends Record({
  id: null,
  name: null,
  unitPrice: null,
  splitPrice: null,
  quantity: null,
}) {
  static constructFromAPIResponse(apiResponse) {
    return new SplitItem({
      id: apiResponse.item_id,
      name: apiResponse.item_name,
      unitPrice: apiResponse.unit_price,
      splitPrice: apiResponse.split_price,
      quantity: new SplitQuantity(apiResponse.quantity),
    });
  }

  get splitPriceString() {
    return formatCurrency(this.splitPrice);
  }

  get unitPriceString() {
    return formatCurrency(this.unitPrice);
  }
}

export const MySplit = (response) => Split.constructFromAPIResponse(response.my_split);

export const Splits = (response) =>
  new OrderedMap(response.splits.map((s) => [s.id, Split.constructFromAPIResponse(s)]));

export default Split;
