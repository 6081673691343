import React, { Component } from 'react';

import Receipt from './receipt';

class Split extends Component {
  renderCheckItems() {
    const { items } = this.props.split;

    return items.map((item) => (
      <div className="item-row" key={`item-${item.id}`}>
        <div className="row">
          <div className="col-8">
            { item.name } ({ item.quantity.toString() })
          </div>
          <div className="col-4">{ item.splitPriceString }</div>
        </div>
      </div>
    ));
  }

  render() {
    const { split } = this.props;
    if (!split) {
      return null;
    }

    return (
      <>
        { this.renderCheckItems() }
        <Receipt
          subtotal={split.subtotal}
          tax={split.tax}
          tip={split.tip}
          serviceFee={split.serviceFee}
          totalPrice={split.totalPrice}
        />
      </>
    );
  }
}

export default Split;