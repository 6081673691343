import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import logo from '../../images/venmo.png';

import {
  claimCheckItem as claimCheckItemAction,
  copyLinkToClipboard as copyLinkToClipboardAction,
  getCheck as getCheckAction,
} from '../actions';

// Components
import CheckItems from '../components/check-items';
import MySplit from '../components/my-split';
import Receipt from '../components/receipt';

import { formatCurrency } from '../util';

class Check extends Component {
  constructor(props) {
    super(props);

    // TODO: Replace with something like this https://stackoverflow.com/questions/32846337/how-to-fetch-the-new-data-in-response-to-react-router-change-with-redux
    const checkId = parseInt(this.props.match.params.check_id);
    const urlParams = new URLSearchParams(window.location.search);
    const splitAuthCode = urlParams.get('auth_code') || '';

    this.state = {
      splitAuthCode,
    };

    this.props.getCheck(checkId, splitAuthCode);

    this.handleClaimCheckItem = this.handleClaimCheckItem.bind(this);
  }

  handleClaimCheckItem({ checkItemId, splitQuantity }) {
    const { check, claimCheckItem } = this.props;

    let params = {
      checkId: check.id,
      checkItemId,
      splitQuantity,
    };

    const { splitAuthCode } = this.state;
    params.splitAuthCode = splitAuthCode;

    claimCheckItem(params);
  }

  renderVenmoDisclaimer() {
    const { check, mySplit, user } = this.props;

    if (check.owner.id !== mySplit.userId) {
      return null;
    }

    if (user.venmoUsername) {
      return null;
    }

    return (
      <small className="form-text text-muted margin-bottom-10">
        Be sure to add your Venmo username on the "My Account" page so people can pay you!
      </small>
    );
  }

  renderPaymentButton() {
    const { check, mySplit, restaurant } = this.props;

    if (check.owner.id === mySplit.userId) {
      return null;
    }

    const venmoLink = encodeURI(
      `https://venmo.com/${check.owner.venmoUsername}?txn=pay&amount=${mySplit.totalPrice}&note=Split It App: Payment for ${restaurant.name}`,
    );

    return (
      <div className="row venmo-button-container">
        <a className="btn btn-primary venmo-button clickable" href={venmoLink} target="_blank" role="button">
          Pay {check.owner.fullName} ${mySplit.totalPrice} on
          <img className="venmo-logo" alt="" src={logo} />
        </a>
      </div>
    );
  }

  renderOtherSplits() {
    const { splits, mySplit } = this.props;

    return splits
      .toList()
      .filter((s) => s.id !== mySplit.id)
      .map((split) => (
        <div className="item-row" key={`split-${split.id}`}>
          <div className="row">
            <div className="col-8">
              <div className="split-color" style={split.displayColors}></div>
              {split.name}
            </div>
            <div className="col-4">{formatCurrency(split.totalPrice)}</div>
          </div>
        </div>
      ));
  }

  renderCopyLinkButton() {
    const { check, copyLinkToClipboard } = this.props;

    return (
      <button
        className="btn btn-secondary btn-sm margin-bottom-10"
        onClick={() => copyLinkToClipboard(check.shareCode)}
      >
        Copy Link to Invite Others
      </button>
    );
  }

  renderHowItWorksModal() {
    return (
      <div
        className="modal fade"
        id="howItWorksModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="howSplitItWorksModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="howSplitItWorksModalLabel">
                How Split It Works
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ul>
                <li>Claim each item you had</li>
                <li>Fully claimed items will show up as green</li>
                <li>Your Tax and Tip will be automatically calculated based on how much you claim</li>
                <li>Click on your name to see your Split's itemized breakdown</li>
                <li>
                  The Venmo button will open the Venmo app with a pre-filled payment to the person who picked up the
                  bill
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { check, checkItems, mySplit, restaurant, splits } = this.props;

    if (!check.id) {
      return null;
    }

    const restaurantName = restaurant?.name;
    const title = `Split the bill for ${restaurantName}`;

    return (
      <div className="col-sm-6 col-xs-12">
        <Helmet>
          <title>{title} - Split It App</title>
          <meta property="og:title" content={title} />
        </Helmet>
        <h3>{restaurant.name}</h3>
        <h5>{check.formattedDate}</h5>
        {check.isCompleted && (
          <h6>
            <span class="badge badge-pill badge-success badge-small">Completed</span>
          </h6>
        )}
        {this.renderCopyLinkButton()}
        {this.renderVenmoDisclaimer()}
        <br />
        <button className="btn btn-light btn-sm margin-bottom-10" data-toggle="modal" data-target="#howItWorksModal">
          Read how Split It works
        </button>
        {this.renderHowItWorksModal()}
        <MySplit mySplit={mySplit} />
        {this.renderPaymentButton()}
        {this.renderOtherSplits()}

        <h5 className="margin-top-10">Items on this Bill</h5>
        <CheckItems
          handleClaimCheckItem={this.handleClaimCheckItem}
          items={checkItems}
          mySplit={mySplit}
          splits={splits}
        />
        <Receipt
          subtotal={check.subtotal}
          tax={check.tax}
          tip={check.tip}
          serviceFee={check.serviceFee}
          totalPrice={check.totalPrice}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  check: state.check,
  checkItems: state.checkItems,
  mySplit: state.mySplit,
  restaurant: state.restaurant,
  splits: state.splits,
  user: state.user,
});

export default connect(mapStateToProps, {
  getCheck: getCheckAction,
  claimCheckItem: claimCheckItemAction,
  copyLinkToClipboard: copyLinkToClipboardAction,
})(Check);
